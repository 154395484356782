import React, {useEffect} from 'react';
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';


const HomeTemplate = props => {
  const {
    pageContext: { lang, slug }
  } = props;

  const i18n = new I18n({ lang });
  let s3AssetsPath = process.env.GATSBY_S3_ASSETS_PATH;
  var intervalFix = null
  

  useEffect(() => {
    const quotesSection = document.getElementById('tbcommunity-quotes');
      if(quotesSection) {
        customElements.whenDefined("tm-quotes-a").then(() => {      
          intervalFix = setInterval(() => {
            const firstQuote = quotesSection.shadowRoot.querySelectorAll('lwc-tm-quote-card')[0] || null;
            if(firstQuote){
              firstQuote.shadowRoot.querySelectorAll('.quote-card--hero')[0].classList.remove('quote-card--hero');
              clearInterval(intervalFix)
            }
          }, 100)
        })  
      }
  }, [])

  return (
    <Layout slug={slug} titleOverride="">
      <tm-page className="theme--trailhead">
        <div data-cookie-show="apex__dfc_user_info" className="slds-hide">
          <tm-hero-a
            title={i18n.text('hero_title')}
            subtitle={i18n.text('hero_subtitle')}
            cta-label={i18n.text('hero_ctaText')}
            cta-href="https://trailhead.salesforce.com/trailblazer-community/feed"
            img-src={`${s3AssetsPath}/2024_header.png`}
            img-alt={''}
          ></tm-hero-a>
        </div>
        <div data-cookie-hide="apex__dfc_user_info">
          <tm-hero-a
            title={i18n.text('hero_title')}
            subtitle={i18n.text('hero_subtitle')}
            cta-label={i18n.text('hero_ctaUnloggedText')}
            cta-href="https://trailhead.salesforce.com/trailblazer-community/feed"
            img-src={`${s3AssetsPath}/2024_header.png`}
            img-alt={''}
          ></tm-hero-a>
        </div>

        <tm-section-a
          title={i18n.text('section_A_title')}
          background="light">
          <center>
            <div class="slds-container_medium">
              <p dangerouslySetInnerHTML={{ __html: i18n.text('section_A_subtitle')}}></p>
            </div>
            <img style={{width: '890px', maxWidth: '100%'}} src={`${s3AssetsPath}/community.png`} alt='' />
            <p>
              * <a href="https://valoir.com/blog-1/salesforce-trailblazer-community" target="_blank">https://valoir.com/blog-1/salesforce-trailblazer-community</a>
            </p>
          </center>
         
        </tm-section-a>

        <tm-section-a>
          <tm-feature-grid-a>
            <tm-grid-item
              img-src={`${s3AssetsPath}/learn.png`}
              img-alt={''}
              title={i18n.text("feature_1_title")}
              description={i18n.text("feature_1_description")}
              cta-primary-title={i18n.text("feature_1_cta_1_label")}
              cta-primary-href="https://trailhead.salesforce.com/trailblazer-community/topics#featured"
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/connect.png`}
              img-alt={''}
              title={i18n.text("feature_2_title")}
              description={i18n.text("feature_2_description")}
              cta-primary-title={i18n.text("feature_2_cta_1_label")}
              cta-primary-href="https://trailhead.salesforce.com/trailblazer-community/groups?tab=featured"
              cta-secondary-title={i18n.text("feature_2_cta_2_label")}
              cta-secondary-href="https://trailblazercommunitygroups.com/?&utm_source=trailblazer_community&utm_medium=web-homepage-hero&utm_campaign=TC2"
              cta-secondary-target="true"
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/give-back.png`}
              img-alt={''}
              title={i18n.text("feature_3_title")}
              description={i18n.text("feature_3_description")}
              cta-primary-title={i18n.text("feature_3_cta_1_label")}
              cta-primary-href="https://trailhead.salesforce.com/trailblazer-community/feed?tab=questions&filter=UNSOLVED_QUESTIONS"
            ></tm-grid-item>
          </tm-feature-grid-a>
        </tm-section-a>

        <tm-section-a
          title={i18n.text('meetTrailblazers_title')}
          description={i18n.text('meetTrailblazers_description')}>

          
            <tm-quotes-a id="tbcommunity-quotes">
              <tm-quote
                img-src={`${s3AssetsPath}/${i18n.text('trailblazerCard_1_image_filename')}`}
                img-alt={''}
                author={i18n.text('trailblazerCard_1_author_name')}
                author-link={i18n.text('trailblazerCard_1_author_link')}
                role={i18n.text('trailblazerCard_1_role')}
                text={i18n.text('trailblazerCard_1_description')}
              ></tm-quote>
              <tm-quote
                img-src={`${s3AssetsPath}/${i18n.text('trailblazerCard_2_image_filename')}`}
                img-alt={''}
                author={i18n.text('trailblazerCard_2_author_name')}
                author-link={i18n.text('trailblazerCard_2_author_link')}
                role={i18n.text('trailblazerCard_2_role')}
                text={i18n.text('trailblazerCard_2_description')}
              ></tm-quote>
              <tm-quote
                img-src={`${s3AssetsPath}/${i18n.text('trailblazerCard_3_image_filename')}`}
                img-alt={''}
                author={i18n.text('trailblazerCard_3_author_name')}
                author-link={i18n.text('trailblazerCard_3_author_link')}
                role={i18n.text('trailblazerCard_3_role')}
                text={i18n.text('trailblazerCard_3_description')}
              ></tm-quote>
            </tm-quotes-a>

          {
            /*
          <tm-card-grid-a img-type="full">
            <tm-grid-item
              img-src={`${s3AssetsPath}/trailblazer-1.png`}
              img-alt={i18n.text('trailblazerCard_1_title')}
              title={i18n.text('trailblazerCard_1_title')}
              description={i18n.text('trailblazerCard_1_description')}
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/trailblazer-2.png`}
              img-alt={i18n.text('trailblazerCard_2_title')}
              title={i18n.text('trailblazerCard_2_title')}
              description={i18n.text('trailblazerCard_2_description')}
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/trailblazer-3.png`}
              img-alt={i18n.text('trailblazerCard_3_title')}
              title={i18n.text('trailblazerCard_3_title')}
              description={i18n.text('trailblazerCard_3_description')}
            ></tm-grid-item>
          </tm-card-grid-a>
          */
          }
        </tm-section-a>

        <tm-section-a
          background="light"
          title={i18n.text('learMore_title')}>
          <tm-card-grid-a img-type="full">
            <tm-grid-item
              img-src={`${s3AssetsPath}/learnMore2.png`}
              img-alt={''}
              title={i18n.text('learMore_card_2_title')}
              description={i18n.text('learMore_card_2_description')}
              cta-title={i18n.text('learMore_card_2_ctaLabel')}
              cta-href={`https://trailhead.salesforce.com/${lang}/content/learn/modules/trailblazer-community-quick-look?&utm_source=trailblazer_community&utm_medium=web-banner&utm_campaign=TC2`}
              cta-target="_blank"
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/learnMore3.png`}
              img-alt={''}
              title={i18n.text('learMore_card_3_title')}
              description={i18n.text('learMore_card_3_description')}
              cta-title={i18n.text('learMore_card_3_ctaLabel')}
              cta-href="https://salesforce.vidyard.com/watch/P2GPzxrrMcm2pFzYHEQVfw?&&utm_source=trailblazer_community&utm_medium=web-banner&utm_campaign=TC2"
              cta-target="_blank"
            ></tm-grid-item>
            <tm-grid-item
              img-src={`${s3AssetsPath}/learnMore1.png`}
              img-alt={''}
              title={i18n.text('learMore_card_1_title')}
              description={i18n.text('learMore_card_1_description')}
              cta-title={i18n.text('learMore_card_1_ctaLabel')}
              cta-href="https://trailhead.salesforce.com/trailhead-go?&utm_source=trailblazer_community&utm_medium=web-banner&utm_campaign=TC2"
              cta-target="_blank"
            ></tm-grid-item>
          </tm-card-grid-a>
        </tm-section-a>

          <tm-end-cap-a
            background="dark"
            img-src={`${s3AssetsPath}/endCap.svg`}
            img-alt={''}
            title={i18n.text('endCap_title')}
            cta-label={i18n.text('endCap_ctaLabel')}
            cta-href="https://trailhead.salesforce.com/trailblazer-community/feed"
          ></tm-end-cap-a>
      </tm-page>
    </Layout>
  );
};

export default HomeTemplate;
